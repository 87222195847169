@import "../../core/index.module";

.clear {
  clear: both;
}

.two-columns {
  box-shadow: 4px 4px 20px -7px rgba(25, 15, 51, 1.25);
  display: flex;
  font-family: $font-family-roboto-regular;
  font-size: 14px;
  margin: 10px -10px;

  .title {
    display: block;
    font-family: $font-family-roboto-bold;
    font-size: 20px;
    margin-bottom: 0px;
    padding: unset;
    text-align: center;
    width: 100%;
  }
}

.small {
  font-size: 17px !important;
}

.column {
  color: white;
  padding: 25px;
  width: 50%;
}

.column-left {
  background-image: url("bkg-cyan.png");
}

.column-right {
  background-image: url("bkg-magenta.png");
}

.glass-area {
  background: rgba(255, 255, 255, 0.11);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.3px);
  -webkit-backdrop-filter: blur(4.3px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 10px;
}

.line {
  height: fit-content;
  margin-bottom: 10px;
  padding: 5px;
  width: 100%;
}

.image-middle {
  display: block;
  margin: auto;
}

@media only screen and (max-width: 950px) {
  .two-columns {
    display: block;
  }

  .column {
    padding: 15px;
    width: calc(100% - 30px);
    // margin-left: -10px;
  }
}
