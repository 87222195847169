@mixin font-face($name) {
  @font-face {
    font-family: $name;
    src: url("../assets/fonts/#{$name}.woff2") format("woff2"),
      url("../assets/fonts/#{$name}.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }
}

@include font-face("Inconsolata-Regular");
@include font-face("Lato-Bold");
@include font-face("Lato-Light");
@include font-face("Lato-Medium");
@include font-face("Lato-Regular");
@include font-face("RobotoMono-Bold");
@include font-face("RobotoMono-Medium");
@include font-face("RobotoMono-Regular");
@include font-face("SFMono-Semibold");
@include font-face("SourceSansPro-Bold");
@include font-face("SourceSansPro-Regular");
@include font-face("SourceSansPro-SemiBold");

$font-family-base: SourceSansPro-Regular;
$font-family-inconsolata: Inconsolata;
$font-family-lato-bold: Lato-Bold;
$font-family-lato-light: Lato-Light;
$font-family-lato-medium: Lato-Medium;
$font-family-lato-regular: Lato-Regular;
$font-family-roboto-bold: RobotoMono-Bold;
$font-family-roboto-medium: RobotoMono-Medium;
$font-family-roboto-regular: RobotoMono-Regular;
$font-family-sf-semibold: SFMono-Semibold;
