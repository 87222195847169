.scratched {
  text-decoration: line-through;
}

.image-big {
  display: block;
  margin: auto;
  margin-top: 50px;
  width: 30%;
  height: fit-content;
}

.combine-images-area {
  height: 355px;
  margin-top: 20px;
  position: relative;
  z-index: 2;
}

.image-left {
  height: 300px;
  left: 0px;
  position: absolute;
}

.image-right {
  height: 300px;
  position: absolute;
  right: 0px;
}

.translate-text {
  font-style: italic;
  margin: auto;
  width: fit-content;
}

.arrow {
  display: block;
  margin: auto;
  width: 100px;
}

.diagram-area {
  display: flex;
  margin: auto;
  width: 70%;
  margin-top: 35px;
  margin-bottom: 35px;
}

.diagram-area-small {
  width: 40% !important;
}

@media only screen and (max-width: 1200px) {
  .image-big {
    width: 60%;
  }

  .combine-images-area {
    height: 130px;
    margin-top: 20px;
  }

  .image-left {
    height: 130px;
  }

  .image-right {
    height: 130px;
  }

  .arrow {
    rotate: 90deg;
  }

  .diagram-area {
    display: block;
  }
}
