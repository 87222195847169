.no-margin {
  margin: 0px;
}

.colored-square {
  width: 20px;
  height: 20px;
  float: left;
  margin-right: 5px;
  border: 1px solid black;
  border-radius: 2px;
  clear: both;
  margin-bottom: 5px;
}

.red {
  background-color: #e00000;
}

.magenta {
  background-color: #b1007b;
}

.blue {
  background-color: #1e1da9;
}

.green {
  background-color: #009200;
}

.cyan {
  background-color: #0089a5;
}

.white {
  background-color: white;
}
