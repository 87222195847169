.local-post-page {
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 31%,
    rgba(241, 243, 255, 1) 99%
  );
  overflow-x: hidden;
  padding: 20px 0px;
}

.back-btn {
  background-color: rgb(241, 243, 255);
  border-radius: 24px;
  margin-left: 10px;
  position: fixed;
  top: 65px;
  z-index: 2000;
}

.post-text-area {
  margin-top: 25px;
}

.content-area {
  background-color: white;
  padding: 50px 0px;

  &:last-child {
    padding-bottom: 50vh;
  }
}

.end-marker {
  display: block;
  margin: auto;
  width: 200px;
}

.footer-text {
  display: block;
  font-style: italic;
  font-size: 20px;
  color: grey;
  margin: auto;
  width: fit-content;
}
