$colors-purple-1: #4519d2;
$colors-purple-2: #9076e6;
$colors-purple-3: #f9f8fd;
$colors-purple-4: #f3f0ff;
$colors-purple-5: #cec5f0;
$colors-purple-6: #bfb2f0;
$colors-purple-7: #a896ef;
$colors-purple-8: #eae5fd;
$colors-purple-9: #12023c;
$colors-purple-10: #a59dba;
