@import "../core/index.module";

.title {
  font-family: $font-family-roboto-bold;
  font-size: 30px;
  margin-bottom: 35px;
  padding: 10px;
  padding-top: 0px;
  text-align: center;
}

.italic {
  font-style: italic;
}

.bold {
  font-family: $font-family-roboto-bold !important;
}

.code {
  font-family: 'Courier New', Courier, monospace;
}

.sub-title {
  font-family: $font-family-roboto-bold;
  font-size: 20px;
  margin: 0px 0px 10px 25%;
  padding-top: 55px;
}

.sub-title-small {
  font-family: $font-family-lato-light;
  font-style: italic;
  font-size: 20px;
  margin: 0px 0px 10px 25%;
  padding-top: 55px;
}

.text-area {
  font-family: $font-family-roboto-regular;
  margin: auto;
  width: 50%;
}

.image-area {
  margin: 10px 10px;
  float: left;
}

.image-large {
  width: 50%;
}

.code-area {
  font-family: 'Courier New', Courier, monospace;
  color: lightgray;
  background-color: darkslategray;
  border-radius: 5px;
  padding: 20px;
}

@media only screen and (max-width: 950px) {
  .sub-title {
    margin-left: 10%;
  }

  .sub-title-small {
    margin-left: 10%;
  }

  .text-area {
    width: 80%;
    word-wrap: break-word;
    hyphens: auto;
  }

  .image-large {
    width: 95%;
  }
}