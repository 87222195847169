@import "./core/index.module";

.menu-tabs {
  margin: auto;
  padding-left: 70px;

  .menu-tab {
    font-family: $font-family-base;
  }
}

.menu-toolbar {
  min-height: 48px !important;
}

.flag-top {
  margin-left: 10px;
  margin-top: 4px;
}
