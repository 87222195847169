@import "../core/index.module";

.base {
  background-color: $colors-purple-3;
  height: 100%;
  width: 100%;
}

.about-me-area {
  background-color: white;
  height: fit-content;
  margin: auto;
  padding-top: 49px;
  width: 70%;
}

.banner-area {
  width: 100%;
}

.description-area {
  background-color: white;
  font-family: $font-family-roboto-medium;
  margin: auto;
  margin-top: 20px;
  position: relative;
  text-align: center;
  padding: 20px 20%;
  width: 60%;
}

@media only screen and (max-width: 950px) {
  .base {
    overflow-x: hidden;
  }

  .about-me-area {
    width: 95%;
  }

  .banner-area {
    margin-left: -30%;
    width: 160%;
  }

  .description-area {
    margin: auto;
    margin-top: 10px;
    padding: unset;
    position: relative;
    top: 10px;
    width: 90%;
  }
}

// Classes for Professional Experience Section
.section-experience {
  background-color: white;
  height: fit-content;
  padding: 30px 0px;
  width: 100%;
}

.experience-conferences {
  display: flex;
  height: 475px;
  width: 100%;
}

.experience-1 {
  display: flex;
  min-height: 730px;
  width: 100%;

  .vertical-line {
    height: 1100px;
  }
}

.experience-2 {
  display: flex;
  height: 385px;
  width: 100%;

  .vertical-line {
    height: 400px;
  }
}

.experience-3 {
  display: flex;
  height: 745px;
  width: 100%;
}

.experience-4 {
  display: flex;
  height: 475px;
  width: 100%;
}

.experience-5 {
  display: flex;
  height: 475px;
  width: 100%;
}

.graduation {
  display: flex;
  height: 310px;
  width: 100%;

  .vertical-line {
    height: 370px;
  }
}

.pos-relative {
  position: relative;
  margin-left: 14px;
  top: 0px !important;
}

.pos-fixed {
  position: fixed;
  top: 75px;
  width: calc(35% - 5px) !important;
}

.margin-left-50 {
  margin-left: calc(50% - 5px);
}

.column-1 {
  width: calc(50% - 5px);
}

.column-2 {
  padding-left: 20px;
  padding-top: 45px;
  width: calc(50% - 1px);
}

.vertical-line {
  background-color: $colors-purple-1;
  height: 2100px;
  margin-left: 100%;
  width: 2px;
}

.line-area {
  float: right;
  height: 100px;
  margin-left: calc(100% - 200px);
}

.horizontal-line {
  background-color: $colors-purple-1;
  height: 2px;
  margin-top: 10px;
  width: 300px;
}

.line-label {
  display: block;
  font-family: $font-family-base;
  font-size: 17px;
  margin-right: 10px;
}

.line-date {
  display: block;
  font-family: $font-family-base;
  font-size: 12px;
  margin-right: 10px;
}

.experience-info-area {
  clear: both;
  display: flex;
  margin-bottom: 65px;
  margin-right: 20px;

  img {
    border-radius: 25px;
    box-shadow: 4px 4px 20px -7px rgba(25, 15, 51, 0.25);
  }
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.info-text {
  align-content: center;
  display: flex;
  flex-wrap: wrap;
  font-family: $font-family-base;
  font-style: italic;
  margin-left: 20px;

  &-left {
    float: right;
    margin-right: 20px;
    text-align: right;
  }
}

.small-padding {
  padding: 50px 20px;
  position: static;
  top: 270px !important;
  width: calc(100% - 40px);

  img {
    border-radius: 15px;
  }

  .experience-info-area {
    margin-bottom: 50px;
  }
}

.label-light-title {
  display: flex;
  font-family: $font-family-base;
  font-size: 17px;
  margin: auto;
  width: fit-content;
}

.label-light-date {
  display: flex;
  font-family: $font-family-base;
  font-size: 12px;
  margin: auto;
  width: fit-content;
}

.divider-horizontal {
  background-color: $colors-purple-1;
  height: 2px;
  margin: auto;
  margin-bottom: 20px;
  width: 50%;
}

.section-drawing {
  background-color: $colors-purple-4;
  height: 300px;
  position: relative;
  top: 280px;
  width: 100%;
}
