@import "../core/index.module";

.base-posts {
  background-color: $colors-purple-3;

  .section-contact {
    position: fixed;
    top: calc(100% - 50px);
  }
}

.posts-area {
  background-color: white;
  height: fit-content;
  margin: auto;
  min-height: 100vh;
  padding-bottom: 80px;
  padding-top: 180px;
  width: 70%;
}

.pagination-area {
  margin: 15px auto;
  width: fit-content;
}

.post-area {
  box-shadow: 4px 4px 20px -7px rgba(25, 15, 51, 0.25);
  cursor: pointer;
  display: flex;
  height: fit-content;
  margin: auto;
  margin-bottom: 90px;
  padding: 20px;
  width: 85%;

  &:hover {
    box-shadow: 4px 4px 20px -7px rgba(25, 15, 51, 1);
    color: $colors-purple-1;
  }
}

.post-pic {
  height: 190px;
  margin-right: 15px;
  width: 190px;
}

.post-details {
  width: calc(100% - 200px);
}

@media only screen and (max-width: 950px) {
  .posts-area {
    padding-bottom: 120px;
    padding-top: 210px;
    width: 95%;
  }

  .pagination-area {
    display: none;
  }

  .post-area {
    display: table-row;
  }

  .post-details {
    margin: 20px;
    width: calc(100% - 40px);
  }

  .post-pic {
    margin: 20px;
    margin-left: calc(50% - 95px);
  }

  .space-mobile {
    height: 50px;
  }
}

.first-line {
  display: flex;
  justify-content: space-between;
}

.post-title {
  font-family: $font-family-roboto-bold;
  font-size: 20px;
}

.post-source {
  font-family: $font-family-roboto-regular;
  font-size: 14px;
  margin-bottom: 15px;
}

.post-description {
  font-family: $font-family-roboto-regular;
  font-size: 15px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.post-date {
  font-family: $font-family-sf-semibold;
  font-size: 13px;
  text-align: right;
}

.caricature {
  position: fixed;
  right: 0px;
  top: calc(100% - 300px);
  height: 265px;
}

.hair-color {
  height: 265px;
  position: fixed;
  right: 200px;
  top: calc(100% - 330px);
  // mask: url("../../public/marylia.png");
}

@media only screen and (max-width: 950px) {
  .caricature {
    top: calc(100% - 153px);
    right: 0px;
    height: 110px;
  }

  .hair-color {
    display: none;
  }

  .no-results-area {
    width: 340px;
  }
}

.no-results-area {
  display: block;
  margin: auto;
}

.no-results-text {
  color: $colors-purple-1;
  display: block;
  font-family: $font-family-roboto-medium;
  font-size: 30px;
  font-style: italic;
  margin-top: 25px;
  text-align: center;
}
