.width-full {
    width: 100% !important;
}

.width-half {
    width: 50% !important;
}

@media only screen and (max-width: 1200px) {
    .width-full {
        width: 100% !important;
    }

    .width-half {
        width: 50% !important;
    }
}