@import "../core/index.module";

.filter-area {
  background-color: $colors-purple-8;
  display: flex;
  justify-content: space-between;
  padding: 20px 70px;
  position: fixed;
  top: 48px;
  width: calc(70% - 140px);
}

.count-badge {
  margin: 5px;
}

.search-area {
  margin: auto;
}

.search-input-area {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  width: calc(100% - 110px);
}

.search-input {
  font-family: $font-family-roboto-regular !important;
  margin-top: 6px;
  width: calc(100% - 100px);
}

.icon-right {
  float: right;
}

.language-filter-area {
  background-color: white;
  border: none;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  font-family: $font-family-roboto-regular !important;
  height: 45px;
  margin-bottom: 25px;
  width: 100%;
}

.select-option {
  font-family: $font-family-roboto-regular !important;
}

.select-label {
  font-family: $font-family-roboto-regular !important;
  margin-bottom: 5px;
}

.MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.4) !important;
  border-width: 1px !important;
  outline: none !important;
}

.Mui-focused {
  border-color: none !important;
}

.filter-icon {
  margin-bottom: -7px;
  margin-top: -4px;
}

.MuiBadge-badge {
  margin-top: 10px;
}

.MuiBadge-root {
  height: 45px;
}

.MuiModal-backdrop {
  background-color: unset !important;
}

.filter-modal {
  box-shadow: 4px 4px 20px -7px rgba(25, 15, 51, 0.75);
  position: absolute;
  width: 240px;
  top: 110px;
  right: 20%;
  padding: 25px;
  background-color: white;
  border-radius: 5px;
}

.buttons-area {
  display: flex;
  justify-content: space-between;
}

.button-primary {
  background-color: $colors-purple-1 !important;
}

.button-secondary {
  border: 1px solid $colors-purple-1 !important;
  color: $colors-purple-1 !important;
}

@media only screen and (max-width: 950px) {
  .filter-area {
    justify-content: unset;
    margin-left: -2.5%;
    padding: 20px 25px !important;
    width: 100% !important;
  }

  .search-input-area {
    width: calc(88% - 42px) !important;
  }

  .search-input {
    margin: 7px 15px;
    width: calc(100% - 120px) !important;
  }

  .filter-modal {
    right: 18px;
  }
}
