@import "../core/index.module";

.title-area {
  background-color: $colors-purple-9;
  overflow: hidden;
  height: 200px;
  width: 100%;
}

.title-img-area {
  height: 200px;
  margin: auto;
}

.name {
  color: white;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 30px;
  font-style: italic;
  text-align: center;
  margin: 0 10%;
  width: 80%;
  top: -205px;
  position: relative;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shapes-area {
  position: fixed;
  top: 0px;
}

.shape-0 {
  background-color: white;
  height: 1030px;
  position: fixed;
  width: 100%;
}

.shape-1 {
  background-color: $colors-purple-4;
  clip-path: polygon(50% 100%, 100% 0%, 100% 100%);
  height: 1030px;
  margin-top: -790px;
  position: fixed;
  width: 100%;
}

.shape-2 {
  background-color: $colors-purple-4;
  clip-path: polygon(0% 100%, 0% 0%, 50% 100%);
  height: 1030px;
  margin-top: -790px;
  position: fixed;
  width: 100%;
}

.shape-3 {
  background-color: $colors-purple-5;
  clip-path: polygon(50% 100%, 100% 25%, 100% 100%);
  height: 730px;
  margin-top: -490px;
  position: fixed;
  width: 100%;
}

.shape-4 {
  background-color: $colors-purple-5;
  clip-path: polygon(0% 100%, 0% 25%, 50% 100%);
  height: 730px;
  margin-top: -490px;
  position: fixed;
  width: 100%;
}

.shape-5 {
  background-color: $colors-purple-6;
  clip-path: polygon(50% 100%, 100% 50%, 100% 100%);
  height: 630px;
  margin-top: -390px;
  position: fixed;
  width: 100%;
}

.shape-6 {
  background-color: $colors-purple-6;
  clip-path: polygon(0% 100%, 0% 50%, 50% 100%);
  height: 630px;
  margin-top: -390px;
  position: fixed;
  width: 100%;
}

.shape-7 {
  background-color: $colors-purple-7;
  clip-path: polygon(50% 100%, 100% 75%, 100% 100%);
  height: 530px;
  margin-top: -290px;
  position: fixed;
  width: 100%;
}

.shape-8 {
  background-color: $colors-purple-7;
  clip-path: polygon(0% 100%, 0% 75%, 50% 100%);
  height: 530px;
  margin-top: -290px;
  position: fixed;
  width: 100%;
}
