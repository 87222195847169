@import "../core/index.module";

.flag {
  margin-bottom: 5px;
  margin-left: 5px;
}

.margin-bottom {
  margin-bottom: 25px;
}
