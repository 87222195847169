@import "../core/index.module";

.outer-block {
  background-color: $colors-purple-9;
  color: white;
  border-radius: 5px;
  margin: auto;
  padding: 5px;
  width: fit-content;
  height: fit-content;
}

.outer-block-disabled {
  background-color: $colors-purple-10;
  color: black;

  .inner-block {
    background-color: $colors-purple-10;
    color: black;

    &::before,
    &::after {
      background: $colors-purple-10;
    }
  }
}

.inner-block {
  background-color: $colors-purple-9;
  color: white;
  padding: 20px;
  border: 1px solid;
  position: relative;
  border-radius: 5px;
  width: 125px;
  height: fit-content;
  text-align: center;

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    background: $colors-purple-9;
  }

  &::before {
    top: -2px;
    bottom: -2px;
    left: 15px;
    right: 15px;
  }

  &::after {
    left: -2px;
    right: -2px;
    top: 15px;
    bottom: 15px;
  }

  .content {
    position: relative;
    z-index: 1;
  }
}
