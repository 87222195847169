@import "../core/index.module";

.section-contact {
  background-color: $colors-purple-1;
  color: white;
  height: 50px;
  position: relative;
  top: 50px;
  width: 100%;
}

.social-area {
  display: flex;
  justify-content: space-around;
  margin: auto;
  padding-top: 10px;
  width: 300px;
}

@media only screen and (max-width: 950px) {
  .section-contact {
    top: 0px;
  }
}
