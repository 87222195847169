@import "../core/index.module";

.table-of-contents-area {
    background-color: #f6f6f6;
    border-radius: 20px;
    padding: 20px;
    width: 35%;
    margin: auto;
    margin-bottom: 30px;

    .title-index {
        font-family: $font-family-roboto-bold;
        font-size: 20px;
        text-align: left;
        margin-top: 0px;
        margin-left: 23px;
    }
}

@media only screen and (max-width: 950px) {
    .table-of-contents-area {
        width: 75%;
    }
}
